import BannerBg from "../../../assets/img/SPC-category/2beaec03fcb98eb14e31382b6d78471c.jpg";

export default function DoorPage() {
  return (
    <div>
      <div className="hero" style={{ backgroundImage: `url(${BannerBg})` }}>
        <div className="flex items-center justify-center min-h-screen ">
          <div className="bg-white rounded shadow p-10">
            <h1 className="text-white text-7xl font-bold px-5 py-2 bg-blue-800  text-center rounded-tl-3xl rounded-br-3xl m-10">
              Energy efficient windows and doors Call us <br />
              <a
                href="tel:281-861-6970"
                className="text-blue-200 hover:text-blue-300"
              >
                281-861-6970
              </a>{" "}
              for more details
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}
