import { RouterProvider } from "react-router-dom";
import "./App.css";
import router from "./Routes/Route/Route";
import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   if (typeof window.gtag === "function") {
  //     window.gtag("config", "G-G8KBGFGLVW", {
  //       page_path: window.location.pathname,
  //     });
  //   }
  // }, [window.location.pathname]);

  return (
    <div className="mx-auto">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
