import React from "react";

const GoogleMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27676.055332714805!2d-95.6747!3d29.878491!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d7125719bb09%3A0xc6b6e57cc0a6021f!2sLM%20Tile!5e0!3m2!1sen!2sus!4v1678117271933!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

export default GoogleMap;
